import { usePromiseTracker } from "react-promise-tracker"
import { BeatLoader } from "react-spinners"

export default function LoadingIndicator() {
	const { promiseInProgress } = usePromiseTracker()

	return (
		promiseInProgress ? <div className="fixed inset-0 overflow-y-auto z-[5000]">
			<div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
				{/* This element is to trick the browser into centering the modal contents. */}
				<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
					&#8203;
				</span>
				<div className="inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
					<div>
						<div className="mx-auto flex items-center justify-center">
							<BeatLoader color={"#dbf3ff"} />
						</div>
					</div>
				</div>
			</div>
		</div >
			:
			null
	)
}