import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { trackPromise } from "react-promise-tracker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import TopBar from "./topBar"

import Endpoints from "../lib/endpoints";
import { HTTP } from "../lib/http/client";
import { getSessionStorageFor } from "../lib/http/cookies";
import { type RootState } from "../redux/store";
import { setUser } from "../redux/userSlice";

type Props = {
	children: JSX.Element | JSX.Element[]
}

export default function Layout({ children }: Props) {
	const { i18n } = useTranslation();
	const [searchParams] = useSearchParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const userState = useSelector((state: RootState) => state.user)

	useEffect(() => {
		const fetchUser = async () => {
			if (getSessionStorageFor('token')) {
				const response = await HTTP.get(Endpoints.userMe)
				dispatch(setUser(response.data))
				if (window.location.pathname.includes("login")) {
					navigate("/accidents")
				}
			}
		}

		trackPromise(fetchUser())
	}, [])

	useEffect(() => {
		const lang = searchParams.get('lang')
		if (lang) {
			console.info("LANG: ", lang)
			i18n.changeLanguage(lang);
		}
	}, [])

	return (
		window.location.pathname.includes("login") || !userState.loggedIn ? <div className="flex flex-col h-screen">
			{children}
		</div> : <div className="flex flex-col h-screen">
			<TopBar />
			{children}
		</div>
	)
}