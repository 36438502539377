"use client"

import * as React from "react"

import { format } from "date-fns"
import { ca, es } from "date-fns/locale"
import { Calendar as CalendarIcon } from "lucide-react"
import { useTranslation } from "react-i18next"

import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover"
import { cn } from "@/lib/utils"

type DatePickerProps = {
	value?: Date
	onChange: (date?: Date) => void
	className?: string
}

export function DatePicker({ value, onChange, className }: DatePickerProps) {
	const { t, i18n } = useTranslation()

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					variant={"outline"}
					className={cn(
						"w-full justify-start text-left font-normal",
						!value && "text-muted-foreground",
						className
					)}
				>
					<CalendarIcon className="mr-2 h-4 w-4" />
					{value ? format(value, "P", { locale: i18n.language === "es" ? es : ca }) : <span>{t("accidents.form.select.date")}</span>}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0">
				<Calendar
					mode="single"
					selected={value}
					onSelect={onChange}
					locale={i18n.language === "es" ? es : ca}
					initialFocus
				/>
			</PopoverContent>
		</Popover>
	)
}
