import { useState } from "react";

import { CheckCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { useFormik } from "formik"
import { useTranslation } from "react-i18next";
import { trackPromise } from "react-promise-tracker";

import Logo from "../assets/images/logo-maladeta.svg"
import Endpoints from "../lib/endpoints";
import { HTTP } from "../lib/http/client";

export default function Settings() {
	const { t } = useTranslation()

	const [showSuccess, setShowSuccess] = useState(false)
	const [showError, setShowError] = useState(false)

	const formik = useFormik({
		initialValues: {
			currentPassword: 'currentPassword',
			password: 'userNewPassword',
			passwordConfirmation: 'userNewPassword',
		},
		onSubmit: values => {
			const onSubmit = async () => {
				try {
					await HTTP.post(Endpoints.changePassword, values)
					setShowSuccess(true)
				} catch (err) {
					setShowError(true)
				}
			}

			trackPromise(onSubmit())
		},
	});

	const PasswordChangeSuccesful = () => {
		return (
			<div className="rounded-md bg-emerald-50 p-4 mb-4">
				<div className="flex">
					<div className="shrink-0">
						<CheckCircleIcon className="h-5 w-5 text-emerald-400" aria-hidden="true" />
					</div>
					<div className="ml-3">
						<p className="text-sm font-medium text-emerald-800">{t("settings.passwd_success")}</p>
					</div>
					<div className="ml-auto pl-3">
						<div className="-mx-1.5 -my-1.5">
							<button
								type="button"
								className="inline-flex bg-emerald-50 rounded-md p-1.5 text-emerald-500 hover:bg-emerald-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-emerald-50 focus:ring-emerald-600"
								onClick={() => setShowSuccess(false)}
							>
								<span className="sr-only">Dismiss</span>
								<XMarkIcon className="h-5 w-5" aria-hidden="true" />
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	}

	const PasswordChangeError = () => {
		return (
			<div className="rounded-md bg-red-50 p-4 mb-4">
				<div className="flex">
					<div className="shrink-0">
						<XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
					</div>
					<div className="ml-3">
						<p className="text-sm font-medium text-red-800">{t("settings.error")}</p>
					</div>
					<div className="ml-auto pl-3">
						<div className="-mx-1.5 -my-1.5">
							<button
								type="button"
								className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
								onClick={() => setShowError(false)}
							>
								<span className="sr-only">Dismiss</span>
								<XMarkIcon className="h-5 w-5" aria-hidden="true" />
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className="p-10 space-y-6">
			<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
						<h3 className="text-lg font-medium leading-6 text-gray-900">{t("settings.embed")}</h3>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
						<div className="text-md p-5 bg-blue-50 sm:rounded-lg">
							<code >
								{
									"<iframe src=\"https://backoffice.acna.cat\" title=\"ACNA - Registre d'accidents\" scrolling=\"Yes\" height=\"100%\" width=\"100%\" style=\"border: none;\"></iframe>"
								}
							</code>
						</div>
					</div>
				</div>
			</div>

			<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
				{showSuccess && <PasswordChangeSuccesful />}
				{showError && <PasswordChangeError />}
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
						<h3 className="text-lg font-medium leading-6 text-gray-900">{t("settings.change_passwd")}</h3>
						<p className="mt-1 text-sm text-gray-500">
							{t("settings.change_passwd_subtitle")}
						</p>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
						<form className="space-y-6" onSubmit={formik.handleSubmit}>
							<div>
								<label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
									{t("settings.current_passwd")}
								</label>
								<div className="mt-1">
									<input
										id="currentPassword"
										name="currentPassword"
										type="password"
										required
										className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
										onChange={formik.handleChange}
										value={formik.values.currentPassword}
									/>
								</div>
							</div>
							<div>
								<label htmlFor="password" className="block text-sm font-medium text-gray-700">
									{t("settings.new_passwd")}
								</label>
								<div className="mt-1">
									<input
										id="password"
										name="password"
										type="password"
										required
										className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
										onChange={formik.handleChange}
										value={formik.values.password}
									/>
								</div>
							</div>
							<div>
								<label htmlFor="passwordConfirmation" className="block text-sm font-medium text-gray-700">
									{t("settings.confirm_new_passwd")}
								</label>
								<div className="mt-1">
									<input
										id="passwordConfirmation"
										name="passwordConfirmation"
										type="password"
										required
										className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
										onChange={formik.handleChange}
										value={formik.values.passwordConfirmation}
									/>
								</div>
							</div>
							<div className="flex justify-end pt-6">
								<button
									type="submit"
									className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								>
									{t("settings.change_passwd")}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>

			<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
				<div className="flex flex-row place-content-center space-x-6 mt-5 md:mt-0 md:col-span-2 text-center w-full">
					<a href="https://maladeta.studio">
						<img
							className="block h-12 w-auto"
							src={Logo}
							alt="Maladeta Studio"
						/>
					</a>
				</div>
			</div>
		</div>
	)
}
