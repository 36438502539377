export const COOKIE_DAYS_EXPIRATION = 7;

export function setCookie(cname: string, cvalue: string | number, exdays: number) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue};${expires};Secure;path=/`;
}

export function getCookie(name: string): string | null {
    const nameLenPlus = (name.length + 1);
    return document.cookie
        .split(';')
        .map((c) => c.trim())
        .filter((cookie) => cookie.substring(0, nameLenPlus) === `${name}=`)
        .map((cookie) => decodeURIComponent(cookie.substring(nameLenPlus)))[0] || null;
}

export function setSessionStorage(cname: string, cvalue: string) {
    sessionStorage.setItem(cname, cvalue);
}

export function getSessionStorageFor(name: string) {
    return sessionStorage.getItem(name)
}

export function deleteSessionStorageFor(name: string) {
    sessionStorage.removeItem(name)
}