import { Fragment, useEffect, useRef, useState } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { ChevronLeftIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { PaperClipIcon } from '@heroicons/react/24/solid'
import axios, { type AxiosError } from 'axios';
import moment from 'moment';
import qs from "qs"
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { trackPromise } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Endpoints from '../../lib/endpoints';
import { HTTP } from '../../lib/http/client';
import { type Accident } from '../../models/accident';
import { type RootState } from '../../redux/store';

export default function AccidentsDetails() {
	const { t, i18n } = useTranslation()
	const { accident_id } = useParams()
	const navigate = useNavigate()

	const [openEraseAlert, setOpenEraseAlert] = useState(false)
	const userState = useSelector((state: RootState) => state.user)

	const cancelButtonRef = useRef(null)
	const [accident, setAccident] = useState<Accident>()

	useEffect(() => {
		const fetchData = async () => {
			try {
				const query = qs.stringify({
					populate: ["coordinates",
						"country",
						"region",
						"massi",
						"scope",
						"temporada",
						"orientation",
						"progressio",
						"accuracy",
						"accuracy.units",
						"cota",
						"origen",
						"snow_type",
						"danger_degree",
						"desencadenant",
						"activitat",
						"material",
						"doc",
						"gallery"]
				}, {
					encodeValuesOnly: true,
				});
				const response = await axios.get(Endpoints.accidents + `/${accident_id}?${query}`)
				console.log(response)
				setAccident(response.data.data)
			} catch (error) {
				console.error(error)
				if ((error as AxiosError).response!.status === 404) {
					navigate("/")
				}
			}
		}

		trackPromise(fetchData())
	}, [accident_id])

	const deleteAccident = async ({ id }: { id: string }) => {
		await HTTP.delete(Endpoints.accidents + `/${id}`)
			.then(() => window.location.reload())
			.catch(error => console.error(error))
		window.location.reload()
	}

	return (
		accident ? <>
			<div className='p-4'>
				<div className="py-4 flex space-x-4 items-center">
					<button onClick={() => navigate("/")} className="rounded-full border border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white focus:ring focus:ring-blue-900 p-2">
						<ChevronLeftIcon className='h-5 w-5' />
					</button>
					<div className="text-xl font-medium text-gray-900">{t("accidents.details.title")}</div>
				</div>
				<div className="space-y-5">
					{
						(accident.gallery && accident.gallery.length > 0 && <div className="w-full h-1/4 place-content-center">
							<a href={accident.gallery[0].url}>
								<img src={accident.gallery[0].url} alt={accident.gallery[0].name} className="object-cover w-full h-96 rounded" />
							</a>
						</div>)
					}
					<div className="bg-white shadow overflow-hidden sm:rounded-lg">
						<div className="px-4 py-5 sm:px-6">
							<h3 className="text-lg leading-6 font-medium text-gray-900">{t("accidents.details.location")}</h3>
						</div>
						<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
							<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
								{
									(accident.date && <div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">{t("accidents.models.date")}</dt>
										<dd className="mt-1 text-sm text-gray-900">{moment(accident.date).format("DD/MM/YYYY")}</dd>
									</div>)
								}
								{
									(accident.temporada && <div className="sm:col-span-1">
										<dt className="text-sm font-medium text-gray-500">{t("accidents.models.season")}</dt>
										<dd className="mt-1 text-sm text-gray-900">{accident.temporada.name}</dd>
									</div>)
								}
								<div className="sm:col-span-2">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.scope")}</dt>
									<dd className="mt-1 text-sm text-gray-900">
										{i18n.language === "es" ? (accident.scope?.name_es ?? accident.scope?.name ?? "-") : accident?.scope?.name ?? "-"}
									</dd>
								</div>
							</dl>
						</div>
					</div>
					<div className="bg-white shadow overflow-hidden sm:rounded-lg">
						<div className="px-4 py-5 sm:px-6">
							<h3 className="text-lg leading-6 font-medium text-gray-900">{t("accidents.details.location")}</h3>
						</div>
						<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
							<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.place")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{accident.place ?? "-"}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.country")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{(i18n.language === "es" ? (accident?.country?.name_es ?? accident?.country?.name ?? "-") : accident?.country?.name ?? "-")}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.region")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{(i18n.language === "es" ? (accident?.region?.name_es ?? accident?.region?.name ?? "-") : accident?.region?.name ?? "-")}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.massis")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{(i18n.language === "es" ? (accident?.massi?.name_es ?? accident?.massi?.name ?? "-") : accident?.massi?.name ?? "-")}</dd>
								</div>

								{
									accident.coordinates && <div className="sm:col-span-2">
										<dt className="text-sm font-medium text-gray-500">{t("accidents.details.localization")}</dt>
										<dd className="mt-1 h-60">
											<MapContainer center={(accident ? [accident.coordinates.latitude, accident.coordinates.longitude] : [43.003069514915786, -0.9930763932215235])}
												zoom={10} scrollWheelZoom={false} style={{ height: "100%", width: "100%" }}>
												<TileLayer
													attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
													url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
												/>
												<Marker key={`marker-${accident.coordinates.latitude}`} position={[accident.coordinates.latitude, accident.coordinates.longitude]} />
											</MapContainer>
										</dd>
									</div>
								}
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.coordinates.lat")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{accident.coordinates.latitude}</dd>
								</div>
								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.coordinates.lng")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{accident.coordinates.longitude}</dd>
								</div>

								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.orientation")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{accident?.orientation?.name ?? "-"}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.progressio")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{((i18n.language === "es" ? (accident?.progressio?.name_es ?? accident?.progressio?.name ?? "-") : accident?.progressio?.name) ?? "-")}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.accuracy")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{(i18n.language === "es" ? (accident?.accuracy?.name_es ?? accident?.accuracy?.name ?? "-") : accident?.accuracy?.name ?? "-")} ({accident?.accuracy?.units?.number ?? "-"} {accident?.accuracy?.units?.units ?? "-"})</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.cota")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{(i18n.language === "es" ? (accident?.cota?.name_es ?? accident?.cota?.name ?? "-") : accident?.cota?.name ?? "-")}</dd>
								</div>

							</dl>
						</div>
					</div>
					<div className="bg-white shadow overflow-hidden sm:rounded-lg">
						<div className="px-4 py-5 sm:px-6">
							<h3 className="text-lg leading-6 font-medium text-gray-900">{t("accidents.details.data")}</h3>
						</div>
						<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
							<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.origen")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{(i18n.language === "es" ? (accident?.origen?.name_es ?? accident?.origen?.name ?? "-") : accident?.origen?.name ?? "-")}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.snow_type")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{(i18n.language === "es" ? (accident?.snow_type?.name_es ?? accident?.snow_type?.name ?? "-") : accident?.snow_type?.name ?? "-")}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.size")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{accident.size ?? "-"}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.danger_degree")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{(i18n.language === "es" ? (accident?.danger_degree?.name_es ?? accident?.danger_degree?.name ?? "-") : accident?.danger_degree?.name ?? "-")}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.desencadenant")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{(i18n.language === "es" ? (accident?.desencadenant?.name_es ?? accident?.desencadenant?.name ?? "-") : accident?.desencadenant?.name ?? "-")} ({accident?.desencadenant?.code ?? "-"})</dd>
								</div>

							</dl>
						</div>
					</div>
					<div className="bg-white shadow overflow-hidden sm:rounded-lg">
						<div className="px-4 py-5 sm:px-6">
							<h3 className="text-lg leading-6 font-medium text-gray-900">{t("accidents.details.act_damages")}</h3>
						</div>
						<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
							<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.activitat")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{(i18n.language === "es" ? (accident?.activitat?.name_es ?? accident?.activitat?.name ?? "-") : accident?.activitat?.name ?? "-")}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.material")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{(i18n.language === "es" ? (accident?.material?.name_es ?? accident?.material?.name ?? "-") : accident?.material?.name ?? "-")}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.damage")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{accident.damage ?? "-"}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.members")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{accident.members ?? "-"}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.triggers")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{accident.triggers ?? "-"}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.dragged")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{accident.dragged ?? "-"}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.damaged")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{accident.damaged ?? "-"}</dd>
								</div>


								<div className="sm:col-span-1">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.dead")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{accident.dead ?? "-"}</dd>
								</div>

							</dl>
						</div>
					</div>
					{
						(((userState.loggedIn && accident.doc && accident.doc.length > 0) || accident.link) &&
							<div className="bg-white shadow overflow-hidden sm:rounded-lg">
								<div className="px-4 py-5 sm:px-6">
									<h3 className="text-lg leading-6 font-medium text-gray-900">{t("accidents.details.multimedia")}</h3>
								</div>
								<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
									<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

										{
											((userState.loggedIn && accident.doc && accident.doc.length > 0) &&
												<div className="sm:col-span-2">
													<dt className="text-sm font-medium text-gray-500">{t("accidents.models.docs")}</dt>
													{
														accident.doc.map((doc) => (
															<dd className="mt-1 text-sm text-gray-900">
																<div className="border border-gray-200 rounded-md divide-y divide-gray-200">
																	<div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
																		<div className="w-0 flex-1 flex items-center">
																			<PaperClipIcon className="shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
																			<span className="ml-2 flex-1 w-0 truncate">{doc.name}</span>
																		</div>
																		<div className="ml-4 shrink-0">
																			<a href={doc.url} className="font-medium text-blue-600 hover:text-blue-500">
																				{t("accidents.details.download")}
																			</a>
																		</div>
																	</div>
																</div>
															</dd>
														))
													}
												</div>)
										}
										{
											(accident.link && <div className="sm:col-span-2">
												<dt className="text-sm font-medium text-gray-500">{t("accidents.models.link")}</dt>
												<dd className="mt-1 text-sm text-gray-900">
													<a href={accident.link} className="hover:underline hover:text-blue-800">{accident.link}</a>
												</dd>
											</div>)
										}
									</dl>
								</div>
							</div>)
					}
					<div className="bg-white shadow overflow-hidden sm:rounded-lg">
						<div className="px-4 py-5 sm:px-6">
							<h3 className="text-lg leading-6 font-medium text-gray-900">{t("accidents.details.other")}</h3>
						</div>
						<div className="border-t border-gray-200 px-4 py-5 sm:px-6">
							<dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
								<div className="sm:col-span-2">
									<dt className="text-sm font-medium text-gray-500">{t("accidents.models.observations")}</dt>
									<dd className="mt-1 text-sm text-gray-900">{accident.observations}</dd>
								</div>
							</dl>
						</div>
					</div>
				</div>
				{(userState.loggedIn && <div className="flex justify-end mt-5">
					<Link
						to="edit"
						className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
					>
						{t("accidents.details.buttons.edit")}
					</Link>
					<button
						type='button'
						className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
						onClick={() => {
							if (window.confirm(t("accidents.details.delete.text"))) {
								trackPromise(deleteAccident({ id: `${accident.id}` }))
							}
						}}
					>
						{t("accidents.details.buttons.delete")}
					</button>
				</div>)}
			</div>
			<Transition.Root show={openEraseAlert} as={Fragment}>
				<Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={setOpenEraseAlert}>
					<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0"
							enterTo="opacity-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
						</Transition.Child>

						{/* This element is to trick the browser into centering the modal contents. */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
							&#8203;
						</span>
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
								<div className="sm:flex sm:items-start">
									<div className="mx-auto shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
										<ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
									</div>
									<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
										<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
											{t("accidents.details.delete.title")}
										</Dialog.Title>
										<div className="mt-2">
											<p className="text-sm text-gray-500">
												{t("accidents.details.delete.text")}
											</p>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
										<button
											type="button"
											className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
											onClick={() => {
												if (window.confirm(t("accidents.details.delete.text"))) {
													trackPromise(deleteAccident({ id: `${accident.id}` }))
												}
											}}
										>
											{t("accidents.details.delete.button")}
										</button>
										<button
											type="button"
											className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
											onClick={() => setOpenEraseAlert(false)}
											ref={cancelButtonRef}
										>
											{t("accidents.details.delete.cancel")}
										</button>
									</div>
								</div>
							</div>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</> : <div></div>
	)
}
