import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { type User } from '../models/user'

export interface UserState {
	user: User,
	loggedIn: boolean
}

const initialState: UserState = {
	user: {
		"id": 1,
		"username": "acna",
		"email": "acna@acna.com",
		"provider": "local",
		"confirmed": true,
		"blocked": false,
		"createdAt": "2021-10-14T11:31:13.566Z",
		"updatedAt": "2023-03-10T15:29:59.859Z"
	},
	loggedIn: false
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<User>) => {
			state.user = action.payload
			state.loggedIn = true
		},
		setLoggedIn: (state) => {
			state.loggedIn = true
		},
		logOutUser: (state) => {
			state.loggedIn = false
		}
	},
})

// Action creators are generated for each case reducer function
export const { setUser, setLoggedIn, logOutUser } = userSlice.actions

export default userSlice.reducer