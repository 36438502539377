import { createBrowserRouter } from "react-router-dom";
import Layout from "./components/layout";
import AccidentsData from "./pages/accidents/accidents.data";
import AccidentsDetails from "./pages/accidents/accidents.details";
import AccidentsForm from "./pages/accidents/accidents.form";
import LogIn from "./pages/login";
import Settings from "./pages/settings";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <Layout><AccidentsData /></Layout>,
		errorElement: <Layout><AccidentsData /></Layout>
	},
	{
		path: "/accidents/:accident_id",
		element: <Layout><AccidentsDetails /></Layout>,
	},
	{
		path: "/accidents/:accident_id/edit",
		element: <Layout><AccidentsForm edit /></Layout>,
	},
	{
		path: "/login",
		element: <LogIn />,
	},
	{
		path: "/settings",
		element: <Layout><Settings /></Layout>,
	},
	{
		path: "/accidents/add",
		element: <Layout><AccidentsForm /></Layout>,
	},
]);