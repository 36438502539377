export default class Endpoints {
	static readonly serverURL = import.meta.env.VITE_APP_API_URL;

	static readonly authLogin = Endpoints.serverURL + "/auth/local"
	static readonly userMe = Endpoints.serverURL + "/users/me"
	static readonly accidents = Endpoints.serverURL + "/accidents"
	static readonly scopes = Endpoints.serverURL + "/scopes"
	static readonly accuracies = Endpoints.serverURL + "/accuracies"
	static readonly countries = Endpoints.serverURL + "/countries"
	static readonly dangerDegrees = Endpoints.serverURL + "/danger-degrees"
	static readonly desencadenants = Endpoints.serverURL + "/desencadenants"
	static readonly cotas = Endpoints.serverURL + "/cotas"
	static readonly activitats = Endpoints.serverURL + "/activitats"
	static readonly regions = Endpoints.serverURL + "/regions"
	static readonly massis = Endpoints.serverURL + "/massis"
	static readonly origens = Endpoints.serverURL + "/origens"
	static readonly snowTypes = Endpoints.serverURL + "/snow-types"
	static readonly orientations = Endpoints.serverURL + "/orientations"
	static readonly progression = Endpoints.serverURL + "/progressios"
	static readonly material = Endpoints.serverURL + "/materials"
	static readonly uploads = Endpoints.serverURL + "/upload"
	static readonly uploadFiles = Endpoints.uploads + "/files"
	static readonly changePassword = Endpoints.serverURL + "/auth/change-password"
	static readonly config = Endpoints.serverURL + "/config"
	static readonly seasons = Endpoints.serverURL + "/temporadas"
	static readonly temporadaCSV = Endpoints.serverURL + "/getSeason"
}