import { useEffect, useState } from 'react'

import { PaperClipIcon } from '@heroicons/react/24/solid'
import axios from 'axios'
import clsx from 'clsx';
import { useFormik } from 'formik';
import moment from 'moment';
import qs from "qs"
import { useTranslation } from 'react-i18next';
import { LayersControl, MapContainer, Marker, TileLayer, useMapEvents, WMSTileLayer } from "react-leaflet";
import { trackPromise } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import sortBy from "sort-by"
import * as Yup from 'yup'

import { mapLayers } from '@/components/map/layers';
import { DatePicker } from '@/components/ui/datepicker';
import Endpoints from '@/lib/endpoints';
import { HTTP } from '@/lib/http/client';
import { getSessionStorageFor } from '@/lib/http/cookies';
import { cn } from "@/lib/utils"
import { type Accident, type AccidentPost } from '@/models/accident';
import { type Accuracy } from '@/models/accuracy';
import { type Activitat } from '@/models/activitat';
import { type Cota } from '@/models/cota';
import { type Country } from '@/models/country';
import { type DangerDegree } from '@/models/danger_degree';
import { type Desencadenant } from '@/models/desencadentant';
import { type File as BEFile } from "@/models/file"
import { type Massis } from '@/models/massis';
import { type Material } from '@/models/material';
import { type Orientation } from '@/models/orientation';
import { type Origen } from '@/models/origen';
import { type Progressio } from '@/models/progressio';
import { type Region } from '@/models/region';
import { type Scope } from '@/models/scope';
import { type Season } from '@/models/season';
import { type SnowType } from '@/models/snowType';
import { type Strapi } from "@/models/strapi";
import { type RootState } from '@/redux/store';

type Props = {
	edit?: boolean
}

export default function AccidentsForm({ edit }: Props) {
	const { t } = useTranslation()
	const { accident_id } = useParams()
	const navigate = useNavigate()
	const userState = useSelector((state: RootState) => state.user)

	// External manage data
	const [marker, setMarker] = useState({ latitude: 42.70665956351041, longitude: 0.8679199218750001 });
	const [latitude, setLatitude] = useState(marker.latitude)
	const [longitude, setLongitude] = useState(marker.longitude)
	const [accident, setAccident] = useState<Accident>()
	const [photos, setPhotos] = useState<(File | BEFile)[]>([])
	const [docs, setDocs] = useState<(File | BEFile)[]>([])
	const [deleteDocs, setDeleteDocs] = useState<string[]>([])  //em sembla que aquest no es fa servir XD

	// Form values
	const [temporadas, setTemporadas] = useState<Season[]>([])
	const [scopes, setScopes] = useState<Scope[]>([])
	const [accuracies, setAccuracies] = useState<Accuracy[]>([])
	const [countries, setCountries] = useState<Country[]>([])
	const [dangerDegrees, setDangerDegrees] = useState<DangerDegree[]>([])
	const [desencadenants, setDesencadenants] = useState<Desencadenant[]>([])
	const [cotas, setCotas] = useState<Cota[]>([])
	const [activitats, setActivitats] = useState<Activitat[]>([])
	const [regions, setRegions] = useState<Region[]>([])
	const [massis, setMassis] = useState<Massis[]>([])
	const [origens, setOrigens] = useState<Origen[]>([])
	const [snowType, setSnowType] = useState<SnowType[]>([])
	const [orientation, setOrientation] = useState<Orientation[]>([])
	const [progression, setProgression] = useState<Progressio[]>([])
	const [material, setMaterial] = useState<Material[]>([])

	const MyMap = () => {
		useMapEvents({
			click: (e) => {
				setMarker({
					latitude: e.latlng.lat,
					longitude: e.latlng.lng
				})

				setLatitude(e.latlng.lat)
				setLongitude(e.latlng.lng)
			},
		})
		return null
	}

	useEffect(() => {
		if (!userState.loggedIn) {
			navigate('/')
		}
	}, [])

	/* ---------------- */

	useEffect(() => {
		window.scrollTo(0, 0);
		const getAllFields = async () => {
			try {
				const query = qs.stringify({
					populate: '*'
				}, {
					encodeValuesOnly: true,
				});

				let response = await HTTP.get(Endpoints.accuracies + `?${query}`)
				setAccuracies((response.data.data as Accuracy[]).sort(sortBy('name')))

				response = await HTTP.get(Endpoints.scopes + `?${query}`)
				setScopes((response.data.data as Scope[]).sort(sortBy('name')))

				response = await HTTP.get(Endpoints.countries + `?${query}`)
				setCountries((response.data.data as Country[]).sort(sortBy('name')))

				response = await HTTP.get(Endpoints.dangerDegrees + `?${query}`)
				setDangerDegrees((response.data.data as DangerDegree[]).sort(sortBy('name')))

				response = await HTTP.get(Endpoints.desencadenants + `?${query}`)
				setDesencadenants((response.data.data as Desencadenant[]).sort(sortBy('name')))

				response = await HTTP.get(Endpoints.cotas + `?${query}`)
				setCotas((response.data.data as Cota[]).sort(sortBy('name')))

				response = await HTTP.get(Endpoints.activitats + `?${query}`)
				setActivitats((response.data.data as Activitat[]).sort(sortBy('name')))

				response = await HTTP.get(Endpoints.regions + `?${query}`)
				setRegions((response.data.data as Region[]).sort(sortBy('name')))

				response = await HTTP.get(Endpoints.massis + `?${query}`)
				setMassis((response.data.data as Massis[]).sort(sortBy('name')))

				response = await HTTP.get(Endpoints.origens + `?${query}`)
				setOrigens((response.data.data as Origen[]).sort(sortBy('name')))

				response = await HTTP.get(Endpoints.snowTypes + `?${query}`)
				setSnowType((response.data.data as SnowType[]).sort(sortBy('name')))

				response = await HTTP.get(Endpoints.orientations + `?${query}`)
				setOrientation((response.data.data as Orientation[]).sort(sortBy('name')))

				response = await HTTP.get(Endpoints.progression + `?${query}`)
				setProgression((response.data.data as Progressio[]).sort(sortBy('name')))

				response = await HTTP.get(Endpoints.material + `?${query}`)
				setMaterial((response.data.data as Material[]).sort(sortBy('name')))

				response = await HTTP.get(Endpoints.seasons + `?${query}`)
				setTemporadas((response.data.data as Season[]).sort(sortBy('name')))

			} catch (error) {
				console.error(error)
			}
		}

		trackPromise(getAllFields())
	}, []);


	const formik = useFormik<AccidentPost>({
		enableReinitialize: false,
		initialValues: {
			temporada: edit ? accident?.temporada?.id : undefined,
			date: edit ? accident?.date : undefined,
			place: edit ? accident?.place : undefined,
			damage: edit ? accident?.damage : undefined,
			observations: edit ? accident?.observations : undefined,
			link: edit ? accident?.link : undefined,
			members: edit ? accident?.members : undefined,
			triggers: edit ? accident?.triggers : undefined,
			dragged: edit ? accident?.dragged : undefined,
			damaged: edit ? accident?.damaged : undefined,
			dead: edit ? accident?.dead : undefined,
			scope: edit ? accident?.scope?.id : undefined,
			accuracy: edit ? accident?.accuracy?.id : undefined,
			country: edit ? accident?.country?.id : undefined,
			danger_degree: edit ? accident?.danger_degree?.id : undefined,
			desencadenant: edit ? accident?.desencadenant?.id : undefined,
			coordinates: {
				latitude: marker.latitude,
				longitude: marker.longitude
			},
			size: edit ? accident?.size : undefined,
			cota: edit ? accident?.cota?.id : undefined,
			activitat: edit ? accident?.activitat?.id : undefined,
			region: edit ? accident?.region?.id : undefined,
			massi: edit ? accident?.massi?.id : undefined,
			origen: edit ? accident?.origen?.id : undefined,
			snow_type: edit ? accident?.snow_type?.id : undefined,
			orientation: edit ? accident?.orientation?.id : undefined,
			progressio: edit ? accident?.progressio?.id : undefined,
			material: edit ? accident?.material?.id : undefined,
		},
		validationSchema: Yup.object({
			temporada: Yup.string().required(t('required')),
		}),
		onSubmit: values => {
			const submitData = async () => {
				try {
					// UPLOAD FILES AND THEN UPLOAD ENTRY
					const newValues = values
					newValues.date = moment(values.date).format('YYYY-MM-DD')
					if (newValues.coordinates) {
						newValues.coordinates.latitude = latitude
						newValues.coordinates.longitude = longitude
					}

					if (newValues.temporada) {
						// UPDATE ACCIDENT
						if (edit) {
							const response = await HTTP.put(Endpoints.accidents + `/${accident_id}`, {
								...values,
								...Object.fromEntries(Object.entries(newValues).map(([key, value]) => {
									if (value === undefined || value === "") {
										return [key, null]
									} else {
										return [key, value]
									}
								}))
							})
							const formData = new FormData();
							const formDataDocs = new FormData()


							if (photos.length > 0) {

								photos.forEach(async (image) => {
									if (image instanceof File) {
										formData.append('refId', response.data.data.id);
										formData.append('ref', 'api::accident.accident');
										formData.append('source', 'content-manager');
										formData.append('field', 'gallery');
										formData.append(`files`, image, image.name);
										await HTTP.post(Endpoints.uploads, formData, {
											headers: {
												Authorization: `Bearer ${getSessionStorageFor('token')}`,
												'Content-Type': 'multipart/form-data'
											}
										})
									} else {
										console.error(`La imagen ${image.name} no es un archivo válido.`);
									}
								});

							} else {
								navigate('/')
							}

							if (docs.length > 0) {

								docs.forEach(async (doc) => {
									if (doc instanceof File) {
										formDataDocs.append('refId', response.data.data.id);
										formDataDocs.append('ref', 'api::accident.accident');
										formDataDocs.append('source', 'content-manager')
										formDataDocs.append('field', 'doc');
										formDataDocs.append(`files`, doc, doc.name);
										await HTTP.post(Endpoints.uploads, formDataDocs, {
											headers: {
												Authorization: `Bearer ${getSessionStorageFor('token')}`,
												'Content-Type': 'multipart/form-data'
											}
										}
										)
									} else {
										console.error(`El documento ${doc} no es un archivo válido.`);
									}
								});

							} else {
								navigate('/')
							}

							navigate('/')
						} else {
							// CREATE ACCIDENT
							const formData = new FormData();

							if (photos.length > 0) {
								photos.forEach(image => {
									formData.append(`files.gallery`, image as File, image.name);
								});
							}

							if (docs.length > 0) {
								docs.forEach(doc => {
									formData.append(`files.doc`, (doc as File), doc.name);
								});
							}

							formData.append('data', JSON.stringify({
								...values,
								...Object.fromEntries(Object.entries(newValues).map(([key, value]) => {
									if (value === undefined || value === "") {
										return [key, null]
									} else {
										return [key, value]
									}
								}))
							}))
							await HTTP.post(Endpoints.accidents, formData, {
								headers: {
									Authorization: `Bearer ${getSessionStorageFor('token')}`,
									'Content-Type': 'multipart/form-data'
								}
							})

							navigate('/')
						}

					} else {
						alert("La temporada és obligatòria");
					}
				} catch (error) {
					console.error(error)
				}
			}
			trackPromise(submitData())
		},
	});

	const MapEvents = () => {
		const map = useMapEvents({
			click(e) {
				setMarker({ latitude: e.latlng.lat, longitude: e.latlng.lng })
			},
		})
		return (<></>)
	}

	useEffect(() => {
		const fetchData = async () => {
			let query = qs.stringify({
				populate: '*'
			}, {
				encodeValuesOnly: true,
			});
			let response = await axios.get<Strapi<Accident>>(Endpoints.accidents + `/${accident_id}?${query}`)
			const accident = response.data.data;
			formik.setValues({
				temporada: accident?.temporada?.id,
				date: accident?.date,
				place: accident?.place,
				damage: accident?.damage,
				observations: accident?.observations,
				link: accident?.link,
				members: accident?.members,
				triggers: accident?.triggers,
				dragged: accident?.dragged,
				damaged: accident?.damaged,
				dead: accident?.dead,
				scope: accident?.scope?.id,
				accuracy: accident?.accuracy?.id,
				country: accident?.country?.id,
				danger_degree: accident?.danger_degree?.id,
				desencadenant: accident?.desencadenant?.id,
				coordinates: {
					latitude: marker.latitude,
					longitude: marker.longitude
				},
				size: accident?.size,
				cota: accident?.cota?.id,
				activitat: accident?.activitat?.id,
				region: accident?.region?.id,
				massi: accident?.massi?.id,
				origen: accident?.origen?.id,
				snow_type: accident?.snow_type?.id,
				orientation: accident?.orientation?.id,
				progressio: accident?.progressio?.id,
				material: accident?.material?.id,
			})
			setAccident(response.data.data)
			setMarker({
				latitude: response.data.data.coordinates.latitude,
				longitude: response.data.data.coordinates.longitude
			})

			setLatitude(response.data.data.coordinates.latitude)
			setLongitude(response.data.data.coordinates.longitude)
			query = qs.stringify({
				populate: ['doc', 'gallery']
			}, {
				encodeValuesOnly: true,
			});
			response = await axios.get<Strapi<Accident>>(Endpoints.accidents + `/${accident_id}?${query}`)
			setPhotos(response.data.data.gallery ?? [])
			setDocs(response.data.data.doc ?? [])
		}

		if (edit && accident_id) {
			trackPromise(fetchData())
		}
	}, [])

	return (
		<div className="p-4">
			<div className="py-4 flex space-x-4 items-center">
				<div className="text-xl font-medium text-gray-900">{edit ? "Editar accident" : "Nou accident"}</div>
			</div>
			<form className="space-y-6" onSubmit={formik.handleSubmit}>
				<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
					<div className="md:grid md:grid-cols-3 md:gap-6">
						<div className="md:col-span-1">
							<h3 className="text-lg font-medium leading-6 text-gray-900">{t("accidents.form.date_scope.title")}</h3>
							<p className="mt-1 text-sm text-gray-500">
								{t("accidents.form.date_scope.subtitle")}
							</p>
						</div>
						<div className="mt-5 md:mt-0 md:col-span-2">
							<div className="space-y-6" >
								<div className="grid grid-cols-6 gap-6">
									<div className="col-span-6 sm:col-span-3">
										<div className="flex justify-between items-center">
											<label htmlFor="temporada" className="block text-sm text-left font-medium text-gray-700">
												{t("accidents.models.season")}
											</label>
											{formik.errors.temporada && <div className="text-red-500">{formik.errors.temporada}</div>}
										</div>
										<select
											id="temporada"
											name="temporada"
											className={cn(
												"mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm",
												!formik.values.temporada && " bg-red-100")}
											onChange={formik.handleChange}
											value={formik.values.temporada}
										>
											<option selected value="">{t("accidents.form.select.season")}</option>
											{temporadas.map((temp) => <option value={temp.id}>{temp.name}</option>)}
										</select>
									</div>

									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="date" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.date")}
										</label>
										<div className='mt-1'>
											<DatePicker
												value={formik.values.date ? moment(formik.values.date).toDate() : undefined}
												onChange={(date) => formik.setFieldValue("date", moment(date).toISOString())}
												className={clsx(!formik.values.date && "bg-red-100", "py-4.5")}
											/>
										</div>
									</div>

									<div className="col-span-6">
										<label htmlFor="scope" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.scope")}
										</label>
										<select
											id="scope"
											name="scope"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.scope}
										>
											<option selected value="">{t("accidents.form.select.scope")}</option>
											{scopes.map((scope) => <option value={scope.id}>{scope.name}</option>)}
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
					<div className="md:grid md:grid-cols-3 md:gap-6">
						<div className="md:col-span-1">
							<h3 className="text-lg font-medium leading-6 text-gray-900">{t("accidents.form.geo.title")}</h3>
							<p className="mt-1 text-sm text-gray-500">{t("accidents.form.geo.subtitle")}</p>
						</div>
						<div className="mt-5 md:mt-0 md:col-span-2">
							<div >
								<div className="grid grid-cols-6 gap-6">
									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="place" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.place")}
										</label>
										<input
											type="text"
											name="place"
											id="place"
											placeholder="Baqueira"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.place}
										/>
									</div>

									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="country" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.country")}
										</label>
										<select
											id="country"
											name="country"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.country}
										>
											<option selected value="">{t("accidents.form.select.country")}</option>
											{countries.map((c) => <option value={c.id} label={c.name} />)}
										</select>
									</div>

									<div className="col-span-6 sm:col-span-4">
										<label htmlFor="region" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.region")}
										</label>
										<select
											id="region"
											name="region"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.region}
										>
											<option selected value="">{t("accidents.form.select.region")}</option>
											{regions.map((region) => <option value={region.id}>{region.name}</option>)}
										</select>
									</div>

									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="massi" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.massis")}
										</label>
										<select
											id="massi"
											name="massi"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.massi}
										>
											<option selected value="">{t("accidents.form.select.massis")}</option>
											{massis.map((massis) => <option value={massis.id}>{massis.name}</option>)}
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
					<div className="md:grid md:grid-cols-3 md:gap-6">
						<div className="md:col-span-1">
							<h3 className="text-lg font-medium leading-6 text-gray-900">{t("accidents.form.geo.title")}</h3>
							<p className="mt-1 text-sm text-gray-500">{t("accidents.form.geo.subtitle")}</p>
						</div>
						<div className="mt-5 md:mt-0 md:col-span-2">
							<div className="space-y-6" >
								<div className="grid grid-cols-6 gap-6">
									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="latitude" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.coordinates.lat")}
										</label>
										<input
											type="text"
											name="latitude"
											id="latitude"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={(e) => {
												setLatitude(Number(e.target.value))
												setMarker({ latitude: Number(e.target.value), longitude: marker.longitude })
											}}
											value={latitude}
										/>
									</div>
									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="longitude" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.coordinates.lng")}
										</label>
										<input
											type="text"
											name="longitude"
											id="longitude"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={(e) => {
												setLongitude(Number(e.target.value))
												setMarker({ latitude: marker.latitude, longitude: Number(e.target.value) })
											}}
											value={longitude}
										/>
									</div>
									{ /* Map */}
									<div className="col-span-6 h-96">
										<MapContainer
											center={[marker.latitude, marker.longitude]}
											zoom={7}
											scrollWheelZoom={false}
											style={{ height: "100%", width: "100%" }}
										>
											<MapEvents />
											<LayersControl position="topright">
												{
													mapLayers.map((layer, index) => {
														switch (layer.type) {
															case "wms":
																return (
																	<LayersControl.BaseLayer checked={layer.checked} name={layer.name}>
																		<WMSTileLayer
																			url={layer.url}
																			layers={layer.layer}
																			format={layer.format}
																			attribution={layer.attribution}
																		/>
																	</LayersControl.BaseLayer>
																)
															case "tile":
																return (
																	<LayersControl.BaseLayer checked={layer.checked} name={layer.name}>
																		<TileLayer
																			attribution={layer.attribution}
																			url={layer.url}
																		/>
																	</LayersControl.BaseLayer>
																)
															default:
																return (<>Error</>)
														}
													}
													)
												}
											</LayersControl>
											<MyMap />
											{marker && <Marker key={`marker-${marker.latitude}`} position={[marker.latitude, marker.longitude]} />}
										</MapContainer>
									</div>

									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="orientation" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.orientation")}
										</label>
										<select
											id="orientation"
											name="orientation"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.orientation}
										>
											<option selected value="">{t("accidents.form.select.orientation")}</option>
											{orientation.map((orientations) => <option value={orientations.id}>{orientations.name}</option>)}
										</select>
									</div>

									<div className="col-span-6 sm:col-span-4">
										<label htmlFor="progressio" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.progressio")}
										</label>
										<select
											id="progressio"
											name="progressio"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.progressio}
										>
											<option selected value="">{t("accidents.form.select.progressio")}</option>
											{progression.map((p) => <option value={p.id}>{p.name}</option>)}
										</select>
									</div>

									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="accuracy" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.accuracy")}
										</label>
										<select
											id="accuracy"
											name="accuracy"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.accuracy}
										>
											<option selected value="">{t("accidents.form.select.accuracy")}</option>
											{accuracies.map((accuracy) => <option value={accuracy.id}>{accuracy.name} ({accuracy.units.number} {accuracy.units.units})</option>)}
										</select>
									</div>

									<div className="col-span-6">
										<label htmlFor="cota" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.cota")}
										</label>
										<select
											id="cota"
											name="cota"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.cota}
										>
											<option selected value="">{t("accidents.form.select.cota")}</option>
											{cotas.map((cota) => <option value={cota.id}>{cota.name}</option>)}
										</select>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
					<div className="md:grid md:grid-cols-3 md:gap-6">
						<div className="md:col-span-1">
							<h3 className="text-lg font-medium leading-6 text-gray-900">{t("accidents.form.nivo.title")}</h3>
						</div>
						<div className="mt-5 md:mt-0 md:col-span-2">
							<div className="space-y-6" >
								<div className="grid grid-cols-6 gap-6">
									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="origen" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.origen")}
										</label>
										<select
											id="origen"
											name="origen"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.origen}
										>
											<option selected value="">{t("accidents.form.select.origen")}</option>
											{origens.map((origen) => <option value={origen.id}>{origen.name}</option>)}
										</select>
									</div>

									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="snow_type" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.snow_type")}
										</label>
										<select
											id="snow_type"
											name="snow_type"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.snow_type}
										>
											<option selected value="">{t("accidents.form.select.snow_type")}</option>
											{snowType.map((type) => <option value={type.id}>{type.name}</option>)}
										</select>
									</div>

									<div className="col-span-6 sm:col-span-4">
										<label htmlFor="size" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.size")}
										</label>
										<input
											type="text"
											id="size"
											name="size"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.size}
										/>
									</div>

									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="danger_degree" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.danger_degree")}
										</label>
										<select
											id="danger_degree"
											name="danger_degree"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.danger_degree}
										>
											<option selected value="">{t("accidents.form.select.danger_degree")}</option>
											{dangerDegrees.map((danger) => <option value={danger.id}>{danger.name}</option>)}
										</select>
									</div>

									<div className="col-span-6">
										<label htmlFor="desencadenant-address" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.desencadenant")}
										</label>
										<select
											id="desencadenant"
											name="desencadenant"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.desencadenant}
										>
											<option selected value="">{t("accidents.form.select.desencadenant")}</option>
											{desencadenants.map((desencadenant) => <option value={desencadenant.id}>{desencadenant.name}</option>)}
										</select>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
					<div className="md:grid md:grid-cols-3 md:gap-6">
						<div className="md:col-span-1">
							<h3 className="text-lg font-medium leading-6 text-gray-900">{t("accidents.form.details.title")}</h3>
						</div>
						<div className="mt-5 md:mt-0 md:col-span-2">
							<div className="space-y-6" >
								<div className="grid grid-cols-6 gap-6">
									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="activitat" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.activitat")}
										</label>
										<select
											id="activitat"
											name="activitat"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.activitat}
										>
											<option selected value="">{t("accidents.form.select.activitat")}</option>
											{activitats.map((activitat) => <option value={activitat.id}>{activitat.name}</option>)}
										</select>
									</div>

									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="material" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.material")}
										</label>
										<select
											id="material"
											name="material"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.material}
										>
											<option selected value="">{t("accidents.form.select.material")}</option>
											{material.map((m) => <option value={m.id}>{m.name}</option>)}
										</select>
									</div>

									<div className="col-span-6">
										<label htmlFor="damage" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.damage")}
										</label>
										<textarea
											id="damage"
											name="damage"
											rows={3}
											className="max-w-lg shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
											onChange={formik.handleChange}
											value={formik.values.damage}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
					<div className="md:grid md:grid-cols-3 md:gap-6">
						<div className="md:col-span-1">
							<h3 className="text-lg font-medium leading-6 text-gray-900">{t("accidents.form.stats.title")}</h3>
						</div>
						<div className="mt-5 md:mt-0 md:col-span-2">
							<div className="space-y-6" >
								<div className="grid grid-cols-6 gap-6">
									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="members" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.members")}
										</label>
										<input
											type="number"
											name="members"
											id="members"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.members}
										/>
									</div>

									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="triggers" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.triggers")}
										</label>
										<input
											type="number"
											name="triggers"
											id="triggers"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.triggers}
										/>
									</div>

									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="dragged" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.dragged")}
										</label>
										<input
											type="number"
											name="dragged"
											id="dragged"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.dragged}
										/>
									</div>

									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="damaged" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.damaged")}
										</label>
										<input
											type="number"
											name="damaged"
											id="damaged"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.damaged}
										/>
									</div>

									<div className="col-span-6 sm:col-span-3">
										<label htmlFor="dead" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.dead")}
										</label>
										<input
											type="number"
											name="dead"
											id="dead"
											className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
											onChange={formik.handleChange}
											value={formik.values.dead}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
					<div className="md:grid md:grid-cols-3 md:gap-6">
						<div className="md:col-span-1">
							<h3 className="text-lg font-medium leading-6 text-gray-900">{t("accidents.form.multimedia.title")}</h3>
							<p className="mt-1 text-sm text-gray-500">{t("accidents.form.multimedia.subtitle")}</p>
						</div>
						<div className="mt-5 md:mt-0 md:col-span-2">
							<div className="space-y-6" >
								<div className="grid grid-cols-6 gap-6">
									{ /* Media management */}
									<div className="col-span-6">
										<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.photos")}
										</label>
										{
											(photos.length > 0 ?
												<div className="col-span-6">
													<ul className="mt-4 border border-gray-200 rounded-md divide-y divide-gray-200">
														{
															photos.map((photo, index) => (<li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
																<div className="w-0 flex-1 flex items-center">
																	<PaperClipIcon className="shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
																	<span className="ml-2 flex-1 w-0 truncate">{photo.name}</span>
																</div>
																<div className="ml-4 shrink-0">
																	<button type='button' className="font-medium text-blue-600 hover:text-blue-500"
																		onClick={() => {
																			const deletePhoto = async () => {
																				try {
																					await HTTP.delete(Endpoints.uploadFiles + `/${(photo as BEFile).id}`)
																				} catch (error) {
																					console.error(error)
																				}
																			}
																			setPhotos(photos.filter(function (photo2) {
																				return photo2 !== photo
																			}))
																			if (edit) {
																				trackPromise(deletePhoto())
																			}
																		}}>
																		{t("accidents.form.buttons.delete")}
																	</button>
																</div>
															</li>))
														}
													</ul>
												</div> :
												<div className="mt-2 sm:mt-0 sm:col-span-2">
													<div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
														<div className="space-y-1 text-center">
															<svg
																className="mx-auto h-12 w-12 text-gray-400"
																stroke="currentColor"
																fill="none"
																viewBox="0 0 48 48"
																aria-hidden="true"
															>
																<path
																	d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
																	strokeWidth={2}
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
															<div className="flex text-sm text-gray-600">
																<label
																	htmlFor="file-upload"
																	className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
																>
																	<span>{t("accidents.form.buttons.add_photo")}</span>
																	<input id="file-upload" name="file-upload" type="file" className="sr-only"
																		onChange={(e) => {
																			setPhotos(p => [...p, ...(e.target.files ?? [])])
																		}}
																	/>
																</label>
															</div>
														</div>
													</div>
												</div>
											)
										}
									</div>

									<div className="col-span-6">
										<label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.docs")}
										</label>
										{
											(docs.length > 0 ?
												<div className="col-span-6">
													<div className="flex flex-row my-2 w-full">
														<label
															htmlFor="docs-upload"
															className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm"
														>
															<span>{t("accidents.form.buttons.add_docs")}</span>
															<input id="docs-upload" name="docs-upload" type="file" className="sr-only"
																onChange={(e) => {
																	setDocs(d => [...d, ...(e.target.files ?? [])])
																}}
															/>
														</label>
													</div>
													<ul className="mt-4 border border-gray-200 rounded-md divide-y divide-gray-200">
														{
															docs.map((doc) => (<li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
																<div className="w-0 flex-1 flex items-center">
																	<PaperClipIcon className="shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
																	<span className="ml-2 flex-1 w-0 truncate">{doc.name}</span>
																</div>
																<div className="ml-4 shrink-0">
																	<button type="button" className="font-medium text-blue-600 hover:text-blue-500"
																		onClick={() => {
																			const deleteDocs = async () => {
																				try {
																					await HTTP.delete(Endpoints.uploadFiles + `/${(doc as BEFile).id}`)
																				} catch (error) {
																					console.error(error)
																				}
																			}
																			setDocs(docs.filter(function (doc2) {
																				return doc2 !== doc
																			}))
																			if (edit) {
																				trackPromise(deleteDocs())
																			}
																		}}
																	>
																		{t("accidents.form.buttons.delete")}
																	</button>
																</div>
															</li>))
														}
													</ul>
												</div> :
												<div className="mt-2 sm:mt-0 sm:col-span-2">
													<div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
														<div className="space-y-1 text-center">
															<svg
																className="mx-auto h-12 w-12 text-gray-400"
																stroke="currentColor"
																fill="none"
																viewBox="0 0 48 48"
																aria-hidden="true"
															>
																<path
																	d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
																	strokeWidth={2}
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
															<div className="flex text-sm text-gray-600">
																<label
																	htmlFor="docs-upload"
																	className="relative cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
																>
																	<span>{t("accidents.form.buttons.add_docs")}</span>
																	<input id="docs-upload" name="docs-upload" type="file" className="sr-only"
																		onChange={(e) => {
																			setDocs(d => [...d, ...(e.target.files ?? [])])
																		}}
																	/>
																</label>
															</div>
														</div>
													</div>
												</div>
											)
										}
									</div>
									<div className="col-span-6 sm:col-span-4">
										<label htmlFor="link" className="block text-sm font-medium text-gray-700">{t("accidents.models.link")}</label>
										<div className="max-w-lg flex rounded-md shadow-sm">
											<span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
												https://
											</span>
											<input
												type="text"
												name="link"
												id="link"
												className="flex-1 block w-full focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300 py-2 px-3 border"
												onChange={formik.handleChange}
												value={formik.values.link}
											/>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
					<div className="md:grid md:grid-cols-3 md:gap-6">
						<div className="md:col-span-1">
							<h3 className="text-lg font-medium leading-6 text-gray-900">{t("accidents.form.other")}</h3>
						</div>
						<div className="mt-5 md:mt-0 md:col-span-2">
							<div className="space-y-6">
								<div className="grid grid-cols-6 gap-6">
									<div className="col-span-6 sm:col-span-4">
										<label htmlFor="observations" className="block text-sm font-medium text-gray-700">
											{t("accidents.models.observations")}
										</label>
										<textarea
											id="observations"
											name="observations"
											rows={3}
											className="max-w-lg shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
											onChange={formik.handleChange}
											value={formik.values.observations}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="flex justify-end">
					<button
						type="button"
						className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
						onClick={() => navigate('/')}
					>
						{t("accidents.form.buttons.cancel")}
					</button>
					<button
						type="submit"
						className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
					>
						{edit ? t("accidents.form.buttons.edit") : t("accidents.form.buttons.add")}
					</button>
				</div>
			</form>
		</div>
	)
}