import React from 'react'

import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, RouterProvider, useLocation, useNavigationType } from "react-router-dom";

import LoadingIndicator from './components/loadingIndicator';
import './index.css'
import { store } from './redux/store';
import { router } from './router';
import './i18n/config';

Sentry.init({
	dsn: "https://d5d9bf8e58173f9fa02c048c654e5f8e@o4506070216343552.ingest.us.sentry.io/4506971768225792",
	enabled: process.env.NODE_ENV === "production",
	integrations: [
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes
		}),
	],
	tracesSampleRate: 0.4,
	tracePropagationTargets: ["localhost", /^https:\/\/api\.acna\.cat\/api/],
	replaysSessionSampleRate: 0,
	replaysOnErrorSampleRate: 1.0,
});

const queryClient = new QueryClient()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<RouterProvider router={router} />
				<LoadingIndicator />
			</Provider>
		</QueryClientProvider>
	</React.StrictMode>,
)
